import Vue from 'vue';

import Offset from '@/components/helper/Offset';
import Card from '@/components/material/Card';
import ChartCard from '@/components/material/ChartCard';
import Notification from '@/components/material/Notification';
import StatsCard from '@/components/material/StatsCard';
import BottomMenu from '@/components/core/BottomMenu';
import Header from '@/components/core/Header';
import HeaderSub from '@/components/core/HeaderSub';
import WarnCard from '@/components/core/WarnCard';
import DListItemHtml from '@/components/common/DListItemHtml';
import DImageUpload from '@/components/common/DImageUpload';
import DNotify from '@/components/common/DNotify';
import DButton from '@/components/common/DButton';
import DLabel from '@/components/common/DLabel';
import DSelectbox from '@/components/common/DSelectbox';
import DTextField from '@/components/common/DTextField';
import DNumberField from '@/components/common/DNumberField';
import DImg from '@/components/common/DImg';
import DAlert from '@/components/common/DAlert';
import DTimepicker from '@/components/common/DTimepicker';

Vue.component(Offset.name, Offset);
Vue.component(Card.name, Card);
Vue.component(ChartCard.name, ChartCard);
Vue.component(Notification.name, Notification);
Vue.component(StatsCard.name, StatsCard);
Vue.component(BottomMenu.name, BottomMenu);
Vue.component(Header.name, Header);
Vue.component(HeaderSub.name, HeaderSub);
Vue.component(WarnCard.name, WarnCard);
Vue.component(DListItemHtml.name, DListItemHtml);
Vue.component(DImageUpload.name, DImageUpload);
Vue.component(DNotify.name, DNotify);
Vue.component(DButton.name, DButton);
Vue.component(DLabel.name, DLabel);
Vue.component(DSelectbox.name, DSelectbox);
Vue.component(DTextField.name, DTextField);
Vue.component(DNumberField.name, DNumberField);
Vue.component(DImg.name, DImg);
Vue.component(DAlert.name, DAlert);
Vue.component('DTimepicker', DTimepicker);