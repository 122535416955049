import Vue from 'vue'
function commonFn(){}

const chkOnlyRefferer = () => {
    if (!document.referrer) {
        console.log(document.referrer)
        var _ua = window.navigator.userAgent || window.navigator.vgjendor || window.opera;

        if (_ua.toLocaleLowerCase().indexOf("kakaotalk") > -1)
        {
            window.location.href = (/iPad|iPhone|iPod/.test(_ua)) ? "kakaoweb://closeBrowser" : "kakaotalk://inappbrowser/close";
            window.location.href = "http://plus.kakao.com/talk/bot/@주류/주류정보";
        }
        else if(_ua.toLocaleLowerCase().indexOf("android") > -1)
        {
            window.location.href = "http://plus.kakao.com/talk/bot/@주류/주류정보";            
            self.close();
        }
        else if(_ua.toLocaleLowerCase().indexOf("iphone") > -1 || _ua.toLocaleLowerCase().indexOf("ipad") > -1 || _ua.toLocaleLowerCase().indexOf("ipod") > -1)
        {
            window.location.href = "http://plus.kakao.com/talk/bot/@주류/주류정보";            
            self.close();
        }
        else
        {
            window.location.href = "http://plus.kakao.com/talk/bot/@주류/주류정보";            
            self.close();
        }
    }
    // else{
    //     alert("카카오톡 \‘주류통\’ 채널을 통해 접속해 주세요")
    // }
}

commonFn.chkOnlyRefferer = chkOnlyRefferer

export default commonFn