import { transactionStart, transactionEnd } from '../components/base/LoadingProgress'

export default function({ $axios , store}, inject) {
    // 새로운 엑시오스 인스턴스 생성
    let timeout = 30000*2
    const
    config = {
      baseURL: '',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json; charset=utf-8'
      },
      timeout: timeout
    }

    const api = $axios.create({...config});

    api.interceptors.request.use(
      function ( config ) {
          transactionStart( config, store )
          return config;
      },
      function ( error ) {
          return Promise.reject( error )
      }
    )

    let notify = message => alert( message )   
    api.interceptors.response.use(
      function ( response ) {
          transactionEnd( response.config, store )
          return response.data;
      },
      function ( error ) {
          transactionEnd( error.config, store)
          const response = error.response
          if ( response ) {
              const data = response.data

  /*  메시지에 에러코드 추가하는 로직 주석
              const headers = response.headers
              if ( headers && headers[ 'x-error-code' ] ) {
                  data.message += `[${headers[ 'x-error-code' ]}]`
              }
  */          
              if(response.status === 401){
                  notify( data.message )
                  return new Promise( () => {} )
              }
              if ( data.unknown ) {
                  notify( data.message )
                  return new Promise( () => {} )
              }
              return Promise.reject( data )
          }
          return Promise.reject( {
              message: '인터넷 연결을 확인해주세요.'
          } )
      }
    )
    
    // context에 주입, $api 형태로 호출 가능
    inject("api", api);
  }
   