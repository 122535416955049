import Vue from 'vue'
import notifications from 'vue-notification'

const install = ( Vue ) => {
    Vue.use( notifications )
    const notifyFn = Vue.notify
    const notify = ( type, title, message ) => {
        notifyFn( {
            group: 'global', type: type,
            title: title, text: message,
            duration:2000,
            //duration: type === 'error' || type === 'warn' ? -1 : 1000,
            closeOnClick: false
        } )
    }
    const $notify = ( message, title ) => $notify.success( message, title )
    $notify.success = ( message, title ) => notify( 'success', title, message )
    $notify.warn = ( message, title ) => notify( 'warn', title, message )
    $notify.error = ( message, title ) => notify( 'error', title, message )
    Vue.prototype.$notify = $notify
    Vue.notify = $notify
}

Vue.use( install )
//Vue.use( notifications )