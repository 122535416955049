import Vuex from 'vuex'
import Vue from 'vue'
import nuxtStorage from 'nuxt-storage';

Vue.use( Vuex )

export const state = () => ({
    modal: false,
    isHttpTransaction: false,
    transactionCount: 0,
    popupCount: 0,

    /**스크롤 */
    offsets: {},
    pkey:'',

    /**DAlert */
    msgType:'',
    message:'',
    subMsg:'',
    okCallback:function(){},
    clCallback:function(){},
});

export const getters = {
    modal: state => state.modal,
    isHttpTransaction: state => state.isHttpTransaction,
    transactionCount: state => state.transactionCount,
    popupCount: state => state.popupCount,
    offsets: state => state.offsets,
    pkey: state => {
        if(state.pkey){
            return state.pkey
        }else{
            return nuxtStorage.localStorage.getData('pkey');
        }
    },
};
export const actions = {
    setActionPKey({ commit }, pkey) {
        commit('SET-PKEY', pkey);
        // default time is 5 minutes, set it to 15
        nuxtStorage.localStorage.setData('pkey', pkey, (60*3));
    },
    setRouteOffsetTop({ commit }, [path, offset]) {
        commit('SET-OFFSETS', [path, offset]);
    },
    clearRouteOffset({ commit }) {
        commit('CLEAR-OFFSETS');
    },  
};

export const mutations = {
    'SET-PKEY' : function(state, pkey) {
        state.pkey = pkey
    },
    'CLEAR-OFFSETS' : function(state) {
        state.offsets = {};
    },
    'SET-OFFSETS' : function(state, [path, offset]) {
        state.offsets[path] = offset;
    },
    'SHOW-ALERT': function(state, payload){
        state.msgType = payload.msgType
        state.message = payload.message
        state.okCallback = payload.okCallback
        state.clCallback = payload.clCallback
    },
    'UPDATE-ALERT': function(state, payload){
        state.msgType = payload.msgType
        state.subMsg = payload.subMsg
    },
    'CLOSE-ALERT': function(state, payload){
        state.msgType = payload.msgType
    },    
    'MODAL-TOGGLE': function ( state, value ) {
        state.modal = value
    },
    /* HTTP Transaction */
    'HTTP-TANSACTION-BEGIN': function ( state ) {
        state.isHttpTransaction = true
        ++state.transactionCount
    },
    'HTTP-TANSACTION-END': function ( state ) {
        state.isHttpTransaction = false
    },
    'HTTP-TANSACTION-COUNT-PLUS': function ( state ) {
        ++state.transactionCount
    },
    'HTTP-TANSACTION-COUNT-MINUS': function ( state ) {
        --state.transactionCount
    },
    'POPUP-COUNT-PLUS': function ( state ) {
        ++state.popupCount
    },
    'POPUP-COUNT-MINUS': function ( state ) {
        --state.popupCount
    }    
};
