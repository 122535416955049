export default({app, store}, inject) => {
    //일반 팝업
    const success = ( message = '', okCallback = function(){}, clCallback = function(){}) =>{
        //let { title, message, btns, msgType, width } = params || {}
        store.commit('SHOW-ALERT', { msgType: 'success', message, okCallback, clCallback })
      }

    //전화걸기 팝업
    const callpop = (  message = '', okCallback = function(){}, clCallback = function(){}) => {
        store.commit('SHOW-ALERT', { msgType: 'callpop', message, okCallback, clCallback })
    }
    //전화걸기 팝업
    const callpop2 = (  message = '', okCallback = function(){}, clCallback = function(){}) => {
        store.commit('SHOW-ALERT', { msgType: 'callpop2', message, okCallback, clCallback })
    }    
    //삭제 팝업
    const delpop = (  message = '', okCallback = function(){}, clCallback = function(){}) => {
        store.commit('SHOW-ALERT', { msgType: 'delpop', message, okCallback, clCallback })
    }
    //팝업 메시지 갱신
    const updatepop = (  subMsg = '', msgType='delpop' ) => {
        store.commit('UPDATE-ALERT', { subMsg, msgType })
    }
    const close = (  msgType='delpop' ) => {
        store.commit('CLOSE-ALERT', { msgType })
    }    
    inject('alert', { success, callpop, callpop2, delpop, updatepop, close })
}