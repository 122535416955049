import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

export default (ctx) => {
    const vuetify = new Vuetify({
        theme: {
            themes: {
                light: {
                    primary: '#018fbb',
                    secondary: '#4caf50',
                    tertiary: '#495057',
                    accent: '#82B1FF',
                    error: '#E54D42',
                    info: '#00d3ee',
                    success: '#5cb860',
                    warning: '#ffb648',
                },
            },
        },
        icons: {
            iconfont: 'mdi',
        },
    });

    ctx.app.vuetify = vuetify;
    ctx.$vuetify = vuetify.framework;
};
