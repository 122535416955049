// export default ({ req, store }) => {
//     // if (process.server) {
        
//         const ip = req.connection.remoteAddress || req.socket.remoteAddress;
//         const userAgent = req.headers['user-agent'];
//         const referer = req.headers['referer'];

//         console.log('ip='+ip)
//         console.log(userAgent)
//         console.log(referer)
//         console.log('httpCheck>>>>>>>>>>>>>')
//     // }
// }
//import http from "http";

// export default function({ route }) {
//     console.log('middle ware')
//     console.log(route)
// //   return http.post("http://my-stats-api.com", {
// //     url: route.fullPath,
// //   });
// }
//export default async function({ app, route }) {//
    // 접속한 사용자의 권한 레벨
    //let level = app.$auth.user.level
   // let pageLevel = route.matched[0].components.default.options.level
    //    console.log('middle ware')
   // console.log(route)
    // // 접속하려는 페이지의 레벨보다 낮은 레벨을 가지고 있는 경우
    // if (level < pageLevel) {
    //     // 페이지 접속 불가
    //     return
    // }
//}
export default function({ req, route, redirect, store }) {
    // console.log('httpChecks>>>>>>>>>>>>>>>>>>>>>')
    // if (process.server) {
    //     if(req && req.headers && req.headers.referer){
    //         console.log(req.headers.referer)
    //     }else{
    //         console.log('no referer>>>>>>>>>>>>>>>>>>>.')
    //     }
    //   }    
    //console.log('New request: ' + getRequestURL(req))

    // console.log('cccc')
    // //console.log(route)
    // if(route){
    //     console.log(route.query)
    //     if(route.query){
    //         console.log(route.query.pkey)
    //     }
    // }
    // console.log(req)

    //if (req && req.headers) {///client side에서만 동작하도록
        // console.log('AAAAAAAAAAAAAAAAAAAAAAAAAa')
        // console.log(req)
       // console.log( req.headers['user-agent'])
        //console.log( req.headers['referer'])
   // }
    // const { language, pageName, country } = route.params;
    // const locale = `${language}-${country}`;
  
    // if (SUPPORTED_LOCALES.includes(locale)) return;
  
    // // 현재 로케일이 지원되는 로케일이 아닐 경우 en-us의 콘텐츠로 리다이렉트
    // const redirectRoute = `en/us/${pageName}`;
    // redirect(redirectRoute);
  }
   