const transactionStart = function ( config = { quiet: false }, store) {
    if ( !config.quiet ) {
        store.commit( 'HTTP-TANSACTION-BEGIN' )
    }
    return true
}
const transactionEnd = function ( config = { quiet: false }, store) {
    if ( !config.quiet ) {
        store.commit( 'HTTP-TANSACTION-END' )
        // store.commit( 'HTTP-TANSACTION-COUNT-MINUS' )
        // let count = store.getters.transactionCount
        // if ( count <= 0 ) {
        //     store.commit( 'HTTP-TANSACTION-END' )
        // }
    }

    return false
}

export {
    transactionStart,
    transactionEnd
}